<template>
  <div class="success-card">
    <div class="success-card__image">
      <img :src="require(`@/assets/${image}`)" :alt="alt" />
      <p class="success-card__sub-heading">{{ subHeader }}</p>
      <p class="success-card__pill">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: Image,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    subHeader: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.success-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 10px 0;
  width: 368px;

  &__sub-heading {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #584f49;
  }

  &__pill {
    color: #584f49;
    background: #d4eee9;
    padding: 12px 22px;
    line-height: 26px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 27px;
    text-align: center;
    width: 70%;
    margin: 0 auto 20px auto;
  }
}

img {
  height: 200px;
  width: 368px;
  object-fit: cover;
}
</style>

<template>
  <div class="hero">
    <div class="container">
      <img
        src="@/assets/circleDesktop.webp"
        alt="a big yellow circle"
        class="circle"
      />
      <img
        src="@/assets/flowersDesktop.webp"
        alt="bright coloured flowers that brings happiness"
        class="flowers__top"
      />
      <img
        src="@/assets/sunflower.webp"
        alt="bright coloured sunflower that brings happiness"
        class="sunflower"
      />

      <div class="hero__main">
        <div class="hero__main-left">
          <h1 class="hero__main-left-header">Digital Marketing Manager</h1>
          <p class="hero__main-left-span">
            Experienced digital marketing manager offering bespoke services to
            businesses across the UK, helping put your business on the digital
            map and achieve success for your brand.
          </p>
          <div class="buttons">
            <a @click="onClick('contact')" class="hero__main-left-btn"
              >Contact Me</a
            >
            <a @click="onClick('previous')" class="hero__main-left-btn-invert"
              >Previous Work</a
            >
          </div>
        </div>
        <img
          src="@/assets/lydia-cartoon-desktop-edit.webp"
          alt="picture of Lydia Turner as a cartoon"
          class="lydiaDesktop"
        />
        <img
          src="@/assets/flowersDesktop_bottom.webp"
          alt="bright coloured sunflower that brings happiness"
          class="flowers__bottom"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    onClick(id) {
      // document.getElementById(id).scrollIntoView();
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  width: 50%;
}

.flowers {
  &__top {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 75%;
  }

  &__bottom {
    position: absolute;
    bottom: -80px;
    right: 30%;
    z-index: 1;
    width: 30%;
  }
}

.sunflower {
  position: absolute;
  right: 45%;
  width: 10%;
}

.hero__main {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-top: 3%;

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;

    &-header {
      font-size: 4rem;
      line-height: 5rem;
      margin: 0;
    }

    &-span {
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-top: 22px;
      margin-bottom: 48px;
    }

    &-btn {
      padding: 1rem 2.5rem;
      background: #ff9f66;
      color: white;
      border-radius: 6px;
      border: none;
      font-size: 1.125rem;
      margin-bottom: 16px;
      text-align: center;
      text-decoration: none;
      margin-right: 10px;
    }

    &-btn-invert {
      padding: 1rem 2.5rem;
      border: #b0b4c0 1px solid;
      border-radius: 6px;
      font-size: 1.125rem;
      background: transparent;
      text-align: center;
      text-decoration: none;
      color: black;
    }

    &-socials {
      display: flex;
      align-items: center;
      margin-top: 50px;

      img {
        padding: 20px;
        background: white;
        color: black;
        // border: black solid 1px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      }
    }
    .buttons {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;
    }
  }

  .lydiaDesktop {
    width: 40%;
  }
}

@media (max-width: 1150px) {
  .hero__main {
    &-left {
      &-header {
        font-size: 3.5rem;
        line-height: 3.5rem;
        margin-top: 0;
      }

      &-span {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 22px;
        margin-bottom: 20px;
      }

      &-btn {
        padding: 0.6rem 1.4rem;
      }

      &-btn-invert {
        padding: 0.6rem 1.4rem;
      }
    }
  }

  @media (max-width: 930px) {
    .lydiaDesktop {
      display: none;
    }

    .flowers__bottom {
      display: none;
    }

    .hero__main {
      &-left {
        width: 70%;
        height: 40vh;

        &-header {
          font-size: 2.5rem;
          line-height: 3rem;
        }

        &-span {
          font-size: 1rem;
          line-height: 1.5rem;
          margin-top: 22px;
          margin-bottom: 20px;
        }

        &-btn {
          padding: 0.6rem 1.4rem;
        }

        &-btn-invert {
          padding: 0.6rem 1.4rem;
        }
      }
    }
  }
}
</style>

import { createApp, Vue } from "vue";
import App from "./App.vue";
import "@fortawesome/fontawesome-free/css/all.css";

import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";
import PageNotFound from "@/views/PageNotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/:catchAll(.*)*",
      name: "Page Not Found",
      component: PageNotFound,
      meta: {
        hideNavbar: true,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

const app = createApp(App);

app.use(router);
app.mount("#app");

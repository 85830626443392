<template>
  <div class="about">
    <img
      src="@/assets/yellow-dashes.webp"
      alt="yellow dashes"
      class="dashes-mobile"
    />
    <img
      src="@/assets/pink-eclipse.webp"
      alt="a pink eclipse"
      class="pink-eclipse"
    />
    <h3 class="header">About</h3>
    <p class="header-brief">
      Born in Coventry, West Midlands, I started my career in digital marketing
      following the completion of my Masters in journalism.
    </p>
    <div class="flex">
      <div class="left">
        <img
          src="@/assets/LydiaWithClient.webp"
          alt="Lydia Turner"
          class="lydia"
        />
        <img
          src="@/assets/yellow-dashes.webp"
          alt="yellow dashes"
          class="yellowDashes"
        />
      </div>
      <div class="right">
        <span>3 + Years Experience</span>
        <p class="sub-heading">Digital marketer & PR manager</p>
        <div class="info" v-html="info"></div>
        <a @click="onClick('contact')">Contact me</a>
      </div>
    </div>
    <img
      src="@/assets/green-eclipse.webp"
      alt="a green eclipse"
      class="green-eclipse"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: `<p>
        Living in Sheffield, I started working in digital marketing
        following my degree in Journalism. I went on to take multiple digital marketing courses whilst
        volunteering to help charities and start-ups with their social
        media.
        </p>

        <p>
        Combining my knowledge, I have since worked with over 10 businesses and individuals to deliver short and long term
        goals for their online presence. Creating bespoke digital marketing
        strategies, I have achieved successes for each of my clients
        (varying depending on what success means for them).
        </p>

        <p>
        This could be increasing your page engagement by 125% in the first 3
        months, increasing your sales or creating a regular posting
        structure.
        </p>

        <p>
        Whatever the specialism, I will be sure to
        immerse myself in your culture and understand your target audience
        to deliver a personalised digital marketing strategy that mimics
        your brand voice to a tee.
        </p>
      `,
    };
  },
  methods: {
    onClick(id) {
      // document.getElementById(id).scrollIntoView();
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  overflow-x: hidden;
}

h3 {
  font-size: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
}

.dashes-mobile {
  position: absolute;
  display: none;
  top: -50px;
  right: -50px;
  z-index: -1;
  transform: rotate(355deg);
  width: 40%;
}

.pink-eclipse {
  position: absolute;
  top: 20px;
  width: 20px;
}

.green-eclipse {
  position: absolute;
  right: 10%;
  bottom: 50px;
  width: 35px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 150px 50px 150px;
  position: relative;
  margin: 0 auto;
}

.left {
  position: relative;
}

.left,
.right {
  width: 50%;
  margin-left: 50px;

  span {
    color: #258675;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
  }

  .sub-heading {
    font-weight: 600;
    font-size: 25px;
    line-height: 40px;
    color: #1c0a0a;
    padding: 18px 0;
  }

  .info {
    height: 250px;
    overflow-y: scroll;
    margin-bottom: 50px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #584f49;
    padding-bottom: 5px;
  }

  a {
    color: white;
    background: #ff9f66;
    border-radius: 4px;
    border: none;
    padding: 10px 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: #e9905d;
    }
  }
}

.lydia {
  max-width: 100%;
  min-width: 300px;
  height: auto;
}

.yellowDashes {
  top: -70px;
  left: -120px;
  position: absolute;
  z-index: -1;
}

.header {
  text-align: center;
  color: #1c0a0a;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 20px;

  &-brief {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #584f49;
    width: 50%;
    margin: 0 auto;
  }
}

@media (max-width: 1050px) {
  .flex {
    padding: 50px 50px 50px 50px;
  }
}

@media (max-width: 768px) {
  .flex {
    flex-direction: column;
    padding: 50px 0 50px 0;
    margin: 0;
    width: 100%;
  }

  .yellowDashes {
    display: none;
  }

  .dashes-mobile {
    display: block;
  }

  .left,
  .right {
    width: auto;
    margin: 0;
    padding: 20px 70px;
  }

  .header-brief {
    width: 80%;
    margin: 0 auto;
  }

  .right {
    span {
      color: #258675;
      line-height: 0;
    }

    .sub-heading {
      line-height: 40px;
      padding: 0;
    }
  }
}
</style>

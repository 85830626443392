<template>
  <div class="carousel">
    <flickity :options="flickityOptions" ref="flickityRef">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="carousel-cell"
        :style="{ color: textColour }"
      >
        <img v-show="!needsStyling" :src="item.icon" :alt="altTag" />
        <img
          v-show="needsStyling"
          :src="item.icon"
          class="icon"
          alt="this is an image of a current or previous client"
        />
        <div :class="{ addBorder: needsStyling }">
          <img
            v-show="needsStyling"
            src="@/assets/Ellipse.webp"
            alt="a yellow ellipse"
            class="ellipse"
          />
          <img
            v-show="needsStyling"
            src="@/assets/zigzag.webp"
            alt="a green zigzag"
            class="zigzag"
          />
          <h3>{{ item.subheading }}</h3>
          <p :class="{ addItalic: needsStyling }">{{ item.desc }}</p>
        </div>
      </div>
    </flickity>
  </div>
</template>

<script>
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    pageDots: {
      type: Boolean,
    },
    arrows: {
      type: Boolean,
    },
    selectColour: {
      type: String,
    },
    needsStyling: {
      type: Boolean,
    },
    altTag: {
      type: String,
    },
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        cellAlign: "center",
        wrapAround: true,
        autoPlay: 4000,
        prevNextButtons: this.arrows,
        pageDots: this.pageDots,
        fade: true,
      },
      textColour: this.selectColour,
    };
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  max-width: 100%;
  margin: 0 auto;
}

.carousel-cell {
  width: auto;
  padding: 50px 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

h3 {
  font-size: 30px;
}

.addItalic {
  font-style: italic;
}

.addBorder {
  position: relative;
  padding: 100px 250px;
  background: rgba(255, 255, 255, 0.699);
  box-shadow: 0px 60px 150px -20px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
}

.ellipse {
  position: absolute;
  top: 30px;
  right: -40px;
  z-index: 1;
}

.zigzag {
  position: absolute;
  bottom: 10px;
  left: -70px;
}

.icon {
  position: absolute;
  top: 0;
  z-index: 10;
  width: 140px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

@media (max-width: 1050px) {
  .carousel-cell {
    padding: 80px;
  }

  .addBorder {
    padding: 100px 50px;
  }
}

@media (max-width: 768px) {
  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .addBorder {
    padding: 14px 20px;
  }

  .carousel-cell {
    padding: 50px;
  }

  .zigzag {
    top: -20px;
    width: 100px;
    left: -20px;
  }

  .ellipse {
    width: 50px;
    top: initial;
    left: initial;
    bottom: -10px;
    right: -20px;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 108px;
    aspect-ratio: 1/1;
    border-radius: 50%;
  }
}
</style>

<template>
  <div class="hero__mobile">
    <!-- <img
      src="@/assets/flowers1.webp"
      alt="colourful flowers"
      class="flowers__top"
    /> -->
    <img
      src="@/assets/circle.webp"
      alt="a huge round yellow circle"
      class="circle__mobile"
    />
    <div class="hero__info">
      <h1 class="hero__info-header">Digital Marketing Manager</h1>
      <p class="hero__info-cta-message">
        Experienced digital marketing manager offering bespoke services to
        businesses across the UK, helping put your business on the digital map
        and achieve success for your brand.
      </p>
      <a @click="onClick('contact')" class="hero__info-btn">Contact me</a>
      <a @click="onClick('previous')" class="hero__info-btn-invert"
        >Previous Work</a
      >
      <!-- <router-link to="#contact">Contact me</router-link> -->
    </div>

    <div class="container">
      <img
        src="@/assets/lydia-cartoon.webp"
        alt="a cartoon image of Lydia Turner"
        class="lydia"
      />
      <!-- <img
        src="@/assets/flowers1.webp"
        alt="bright coloured flowers"
        class="flowers__middle"
      /> -->
      <img
        src="@/assets/flowers2.webp"
        alt="bright coloured flowers"
        class="flowers__bottom"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClick(id) {
      // document.getElementById(id).scrollIntoView();
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hero__mobile {
  padding: 0;
  margin: 0;
}

.flowers {
  &__top {
    position: absolute;
    top: -50px;
    right: 0;
    z-index: -2;
    width: 100%;
  }

  &__middle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -4;
  }

  &__bottom {
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;
    z-index: 10;
  }
}

.circle__mobile {
  position: absolute;
  top: -25px;
  left: 0;
  width: 100vw;
  z-index: -3;
}

.hero__info {
  display: flex;
  flex-direction: column;
  position: relative;
  // z-index: -2;
  padding: 15px 20px;
  margin-top: 10%;

  &-header {
    font-size: 36px;
    font-weight: 700;
    margin: 0;
    line-height: 57px;
  }

  &-cta-message {
    font-size: 16px;
    font-weight: 400;
    margin-top: 14px;
    margin-bottom: 25px;
    line-height: 28px;
    color: #584f49;
  }

  &-btn {
    padding: 10px;
    // max-width: 120px;
    background: #ff9f66;
    color: white;
    border-radius: 6px;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    text-align: center;
  }

  &-btn-invert {
    padding: 10px;
    // max-width: 120px;
    border: #b0b4c0 1px solid;
    border-radius: 6px;
    font-size: 18px;
    background: transparent;
    text-align: center;
  }
}

.container {
  position: relative;
  padding: 0;
}

.lydia {
  position: relative;
  width: 100%;
  height: 100%;
  bottom: -10px;
  z-index: 0;
}
</style>

<template>
  <div class="reviews">
    <img
      src="@/assets/Flowers-right.webp"
      alt="colourful flowers"
      class="flowers"
    />
    <h5>Reviews</h5>
    <div class="reviews__carousel">
      <img
        src="@/assets/duoShapes.webp"
        alt="a couple of little shapes"
        class="duoShapes"
      />
      <Carousel
        :data="reviews"
        :alt-tag="reviews.alt"
        :page-dots="true"
        :arrows="false"
        select-colour="black"
        needs-styling="true"
      />
    </div>
  </div>
</template>

<script>
import Carousel from "../Carousel.vue";
export default {
  components: {
    Carousel,
  },

  data() {
    return {
      reviews: [
        {
          icon: require("@/assets/steven.webp"),
          subheading: "Steven Cheng",
          desc: "Lydia creates content and strategy for FlashCharger. She's is always responsive and supportive to the company. I am still in shock that she's able to handle so many tasks at the same time. If a company or a brand is looking for a social media talent, I will definitely recommend Lydia.",
          alt: "Photo of Steven Cheng",
        },
        {
          icon: require("@/assets/dan.webp"),
          subheading: "Dan Taylor",
          desc: "Lydia maintains the social media content for both the ladies and barber side of the company. Lydia is very focused on content and creating the right vibe to suit our online presence and market. Lydia is a real asset to Taylor Taylor.",
          alt: "Photo of Dan Taylor",
        },
        {
          icon: require("@/assets/paul.webp"),
          subheading: "Paul Taylor",
          desc: "Very approachable. Lydia gets to know your brand and marries it in with her social media knowledge. Very efficient in getting things done too.",
          alt: "Photo of Paul Taylor",
        },
        {
          icon: require("@/assets/Keeley.webp"),
          subheading: "Keely Short",
          desc: "Lydia doesn't just manage our social marketing, she has become an integral part of the Excel Martial Arts team! She has captured the voice and tone of the business brand perfectly and has a great way with words... Lydia has been great to bounce ideas around with and come up with ideas that she takes ownership of and delivers every time. Lydia will be a great asset to any team!",
          alt: "Photo of Keeley Short",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.reviews {
  position: relative;
  background: white;
  padding: 50px 0;

  h5 {
    margin-top: 0;
    padding: 0;
  }

  .flowers {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__carousel {
    position: relative;

    .duoShapes {
      position: absolute;
      top: 70px;
      left: 50px;
      z-index: 1;
    }
  }
  h5 {
    font-size: 50px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .reviews {
    padding: 40px;
    .flowers {
      display: none;
    }
    h5 {
      font-size: 40px;
    }
  }
  .duoShapes {
    display: none;
  }
}
</style>

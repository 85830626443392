<template>
  <div>
    <Hero v-if="!mobile" class="hero" />
    <HeroMobile v-if="mobile" class="hero-mobile" />
    <Services id="services-section" ref="services" />
    <About id="about" ref="about" />
    <PreviousWork id="previous" ref="previous" />
    <Reviews />
    <Contact id="contact" ref="contact" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Hero from "@/components/slices/Hero.vue";
import HeroMobile from "@/components/slices/HeroMobile.vue";
import Services from "@/components/slices/Services.vue";
import About from "@/components/slices/About.vue";
import PreviousWork from "@/components/slices/PreviousWork.vue";
import Reviews from "@/components/slices/Reviews.vue";
import Contact from "@/components/slices/Contact.vue";
export default {
  components: {
    NavBar,
    Hero,
    HeroMobile,
    Services,
    About,
    PreviousWork,
    Reviews,
    Contact,
  },
  data() {
    return {
      mobile: false,
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.screenSize = `${window.innerWidth}x${window.innerHeight}`;
      this.mobile = window.innerWidth <= 500;
    },
  },
};
</script>

<template>
  <div class="success">
    <img
      class="flowers"
      src="@/assets/flowers-left.webp"
      alt="colourful flowers"
    />
    <h4 class="header">Previous work</h4>
    <p class="header-brief">
      Check out some examples of the previous work I've completed for my
      clients.
    </p>
    <div class="flex">
      <div v-for="item in success" :key="item">
        <ProgressCard
          :image="item.image"
          alt="an image of a circle"
          :subHeader="item.header"
          :text="item.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProgressCard from "@/components/ProgressCard.vue";
export default {
  components: {
    ProgressCard,
  },
  data() {
    return {
      success: [
        {
          image: "Excel.webp",
          header: "Excel TikTok Marketing",
          text: "15k views on brand new TikTok account",
        },
        {
          image: "FlashCharger.webp",
          header: "FlashCharger social media marketing",
          text: "LinkedIn post impressions surge by 1,486% in 3 months!",
        },
        {
          image: "TaylorTaylorHair.webp",
          header: "Taylor Taylor Hairdressing KPI's",
          text: "New social strategy leads to 200% increase in spend per visit!",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.success {
  position: relative;
  background-color: #f1f6f8;
  padding: 30px 140px;
}

.flowers {
  position: absolute;
  top: -160px;
  left: 0;
  width: auto;
}

.header {
  text-align: center;
  color: #1c0a0a;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 20px;

  &-brief {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #584f49;
    width: 50%;
    margin: 0 auto 50px auto;
  }
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .header {
    font-size: 40px;
    &-brief {
      width: 80%;
    }
  }
  .success {
    position: relative;
    background-color: #f1f6f8;
    padding: 10px 0;
  }

  .flowers {
    display: none;
  }
}
</style>

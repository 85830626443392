<template>
  <div>
    <nav>
      <div v-if="isMobile" class="mobile-nav">
        <div class="navbar-toggle" @click="toggleMenu">
          <HamburgerMenu
            class="hamburger"
            :isActive="showMenu"
            @click.stop="toggleMenu"
          />
        </div>
        <transition>
          <div v-if="showMenu" class="navbar-menu" @click.capture="closeMenu">
            <ul>
              <li @click="$router.push('/')">Home</li>
              <li @click="scrollToSection('services-section')">Skills</li>
              <li @click="scrollToSection('about')">About</li>
              <li @click="scrollToSection('previous')">Previous Work</li>
            </ul>
          </div>
        </transition>
      </div>
      <div v-else class="desktop-nav">
        <ul class="nav-items">
          <li @click="$router.push('/')">Home</li>
          <li @click="scrollToSection('services-section')">Skills</li>
          <li @click="scrollToSection('about')">About</li>
          <li @click="scrollToSection('previous')">Previous Work</li>
        </ul>
        <ul>
          <li class="contact-button" @click="scrollToSection('contact')">
            Contact
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import HamburgerMenu from "./icons/HamburgerMenu.vue";

export default {
  components: {
    HamburgerMenu,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      section.scrollIntoView({ behavior: "smooth" });
    },
  },
  setup() {
    const isMobile = ref(false);
    const showMenu = ref(false);

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const closeMenu = () => {
      showMenu.value = false;
    };

    onMounted(() => {
      const checkScreenSize = () => {
        if (window.innerWidth <= 768) {
          isMobile.value = true;
        } else {
          isMobile.value = false;
        }
      };
      checkScreenSize();
      window.addEventListener("resize", checkScreenSize);
    });

    return {
      isMobile,
      showMenu,
      toggleMenu,
      closeMenu,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.mobile-nav {
  display: flex;
  justify-content: flex-end;

  .hamburger {
    position: relative;
    z-index: 20;
  }
}

.navbar-toggle {
  padding: 10px;
}

.navbar-menu {
  position: absolute;
  width: 60vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 6px;
  box-shadow: 0px 18px 36px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;

  ul {
    list-style-type: none;
    text-align: left;
    margin-top: 30%;

    li {
      padding: 20px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover,
      &.active {
        position: relative;
        color: #ff9f66;
        font-weight: 700;

        &::before {
          content: "";
          position: absolute;
          bottom: 10px;
          left: 20px;
          width: 0%;
          border-bottom: 2px solid #ff9f66;
          border-radius: 1px;
          transition: all 5s ease-in-out;
        }
      }

      &:hover::before,
      &.active::before {
        width: 30%;
      }
    }
  }
}

.desktop-nav {
  position: relative;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  box-shadow: 0px 18px 36px rgba(0, 0, 0, 0.05);
  top: 36px;

  .nav-items {
    display: flex;

    li {
      padding: 10px;
      list-style: none;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .nav-items li {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 8px;
      left: 10px;
      width: 0%;
      height: 2px;
      background-color: #ff9f66;
      transition: all 0.4s ease-in-out;
    }

    &:hover::before,
    &:active::before {
      width: 30%;
    }

    &:hover,
    &:active {
      color: #ff9f66;
      font-weight: 700;
    }
  }

  .contact-button {
    padding: 10px 30px;
    color: #ff9f66;
    border: solid 2px #ff9f66;
    background: #fff1e8;
    border-radius: 5px;
    list-style: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;

    &:hover,
    &:active {
      color: #fff1e8;
      background: #ff9f66;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.v-leave-to {
  transform: translateX(0%);
  opacity: 0;
}
</style>

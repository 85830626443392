<template>
	<div class="page-not-found">
		<div class="error">
			<h1>404 - Page Not Found</h1>
			<p>Check the url! I believe <span>{{ $route.path }}</span> is invalid , don't worry we all do it
				&#128512;</p>
			<router-link to="/">Return to home</router-link>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 100px 50px;
}

a,
span {
	color: #FF9F66;
}

p {
	text-align: center;
}
</style>
<template>
  <div class="contact-form">
    <form @submit.prevent="submitForm">
      <div>
        <label for="name">Name</label>
        <input
          type="text"
          name="name"
          v-model="formData.name"
          placeholder="Name"
          required
        />
      </div>
      <div>
        <label for="email">Email</label>
        <input
          type="email"
          name="email"
          v-model="formData.email"
          placeholder="Email address"
          required
        />
      </div>
      <label for="message">Message</label>
      <textarea
        name="message"
        v-model="formData.message"
        cols="30"
        rows="10"
        resize="none"
        required
      ></textarea>
      <button type="submit">Send Message</button>
    </form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      const serviceID = "service_5sivtpu"; // Replace with your EmailJS service ID
      const templateID = "template_s8zh8rk"; // Replace with your EmailJS template ID
      const userID = "m9XU4uhfdZvvQS3s7"; // Replace with your EmailJS user ID

      emailjs
        .send(serviceID, templateID, this.formData, userID)
        .then(() => {
          this.formData.name = "";
          this.formData.email = "";
          this.formData.message = "";

          // this.$router.push("/success");
          alert("You have successfully sent me an email :)");
        })
        .catch((error) => {
          console.error(error);
          alert("Email sending failed");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-form {
  min-width: 200px;
  padding: 40px 40px;
  box-shadow: 0px 80.6px 105.701px rgba(51, 51, 51, 0.1);
  border-radius: 20px;
  background: white;
}

textarea {
  resize: none;
}

label {
  display: none;
}

input,
select,
textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 15px;
  box-sizing: border-box;
  border: 1px solid #cfcfcf;

  &:focus {
    outline: 1px solid #ff9f66;
  }
}

button {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border: none;
  background: #ff9f66;
  box-shadow: 0px 24px 34px rgba(32, 70, 100, 0.1);
  border-radius: 4px;
  color: white;
  padding: 16px 28px;
  cursor: pointer;

  &:hover {
    background: #ea905c;
  }
}
</style>

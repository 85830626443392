<template>
  <div>
    <footer class="footer">
      <div>
        <p class="copyright">© 2023 Lydia Turner. All rights reserved.</p>
      </div>
      <div>
        <!-- <span>icon1</span> -->
        <a
          href="https://www.linkedin.com/in/lydia-t-156dff1576/"
          target="_blank"
        >
          <i class="fa-brands fa-linkedin fa-2x"></i>
        </a>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 0;
  margin-top: 50px;
}

.copyright {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #584f49;
}

.fa-linkedin {
  color: #584f49;
}

@media (max-width: 768px) {
  .footer {
    padding: 20px;
  }
}
</style>

<template>
	<div class="menu-icon" @click="$emit('toggle')" :class="{ active: isActive }">
		<div class="bar"></div>
		<div class="bar"></div>
		<div class="bar"></div>
	</div>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false
		}
	},
}
</script>

<style scoped lang="scss">
.menu-icon {
	width: 40px;
	height: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	.bar {
		width: 100%;
		height: 6px;
		background-color: white;
		margin-bottom: 5px;
		transition: transform .3s ease;
		border-radius: 5px;
	}
}

.menu-icon.active {
	.bar:nth-child(1) {
		transform: rotate(45deg) translate(18px, 9px);
		background-color: black;
	}

	.bar:nth-child(2) {
		transform: rotate(-45deg) translate(9px, 9px);
		opacity: 0;
	}

	.bar:nth-child(3) {
		transform: rotate(-45deg) translate(4px, 3px);
		background-color: black;
	}
}
</style>


<template>
  <div class="contact">
    <div class="flex">
      <img
        src="@/assets/contact-form-shape.webp"
        alt="a green and orange shape"
        class="shape"
      />
      <div>
        <h6 class="title">Get in Touch With me</h6>
        <p>
          If you have any questions or want to discuss how I can work with your
          business, get in touch. I offer a free 1 hour, no obligation video
          call.
        </p>
      </div>
      <div class="form">
        <Form />
        <img
          src="@/assets/contact-form-shape-mobile.webp"
          alt="a green and orange shape"
          class="shape-mobile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Form from "../Form.vue";
export default {
  components: {
    Form,
  },
};
</script>

<style lang="scss" scoped>
.contact {
  overflow: none;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 50px 0;

  & > div {
    width: 35%;

    p {
      width: 100%;
    }
  }
}

h6 {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  color: white;
  text-align: left;
}

p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: white;
}

.shape {
  position: absolute;
  top: 50px;
  left: 150px;
  z-index: -1;
  width: 80%;
  height: 100%;
}

.shape-mobile {
  display: none;
}

@media (max-width: 1250px) {
  .flex {
    & > div {
      width: 30%;

      h6 {
        font-size: 40px;
      }

      p {
        width: 100%;
      }
    }
  }
}

@media (max-width: 950px) {
  .flex {
    flex-direction: column;

    & > div {
      margin: 0 auto;
      width: 80%;
    }

    h6 {
      color: black;
      font-size: 26px;
      margin-bottom: 0;
      text-align: center;
    }

    p {
      color: black;
      text-align: center;
      font-size: 16px;
    }
  }

  .form {
    position: relative;
  }

  .shape {
    display: none;
  }

  .shape-mobile {
    display: block;
    position: absolute;
    top: -15px;
    left: -50px;
    z-index: -1;
    width: 90vw;
    height: 120%;
  }
}
</style>
